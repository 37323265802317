import NotFound from '../../assets/img/produtos/not-found.jpg';
import {
	Fogao,
	Forno,
	FogaoCompact,
	ChapaMaster,
	CharBroiler,
	Coifa,
} from '../../assets/img/produtos/cookerMasterLine';

import {
	ChapaCompacta,
	CharBroilerCompacta,
	FogaoCompacta,
	FritadeiraCompacta,
	GabineteCompacta,
} from '../../assets/img/produtos/compactLine';

import {
	FreezerHorizontal,
	FreezerVertical,
	RefrigeradorHorizontal,
} from '../../assets/img/produtos/refriMasterLine';

import Distribuicao from '../../assets/img/produtos/distribuicao/distribuicao.png';

import {
	Buffets,
	CarroApoioCoccao,
	CarroApoioForno,
	CarroAuxiliar,
	CarroEsqueleto,
	Estante,
	Guiche,
	Lavatorio,
	MesaChef,
	MesaCorte,
	MesaEncostoCuba,
	MesaLisa,
	PrateleiraGradeada,
	PrateleiraLisa,
	PrateleiraMontante,
	TampoLiso,
	TrayRest,
} from '../../assets/img/produtos/mobiliario';

export const cookerMasterLine = [
	{
		title: 'Fogões a Gás',
		image: Fogao,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Grelhas do tipo radiante em ferro fundido',
			'Queimadores 6500 kcal a gás em ferro fundido de alto rendimento, desmontáveis para facilitar a limpeza',
			'Conjunto de Bandejas Aparadoras em Chapa de Aço Inoxidável AISI 304, liga 18/8',
			'Tubo de Distribuição em Aço Qualificado tratado',
			'Painel frontal em Aço Inoxidável',
			'Registro de Graduação de chama com Manípulos em Baquelite',
		],
	},
	{
		title: 'Forno Para Acoplar ao Fogão',
		image: Forno,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Construção interna em Aço Inoxidável',
			'3 Pares de Trilhos reguláveis para ajuste altura',
			'Prateleira Gradeada',
			'Isolamento térmico em lã de vidro',
			'Padrão Gn’s 2/1',
			'Queimadores 6500 kcal',
		],
	},
	{
		title: 'Fogões a Gás em Linha',
		image: FogaoCompact,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Grelhas do tipo radiante em ferro fundido',
			'Queimadores 6500 kcal a gás em ferro fundido de alto rendimento, desmontáveis para facilitar a limpeza',
			'Conjunto de Bandejas Aparadoras em Chapa de Aço Inoxidável AISI 304, liga 18/8',
			'Tubo de Distribuição em Aço Qualificado tratado',
			'Painel frontal em Aço Inoxidável',
			'Registro de Graduação de chama com Manípulos em Baquelite',
		],
	},
	{
		title: 'Chapa para Grelhados a Gás / Elétrica',
		image: ChapaMaster,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Chapa Superior em Aço Quali cado Tratado 1020 / 12,5 mm de espessura',
			'Queimadores tubulares em Aço Esmaltado',
			'Resistências Elétricas (modelo elétrico)',
			'Controle de temperatura para termostato e lâmpada Piloto (modelo elétrico)',
			'Comandos múltiplos por zona de calor com controles independentes',
			'Registro industrial com graduações de chama',
			'Gaveta coletora de resíduos',
			'Estrutura sobre pés ou gabinete com portas',
			'Painel Frontal em Aço Inoxidável / Manípulos em Baquelite',
		],
	},
	{
		title: 'Char Broiler a Gás',
		image: CharBroiler,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Grelhas de ferro fundido modulares',
			'Bandeja aparadora e suporte frontal móvel para resíduos',
			'Aquecimento a gás através de queimadores multitubulares com transmissão de calor por meio de pedras refratárias para alta produção de grelhados',
			'Painel Frontal em Aço Inoxidável',
			'Registro industrial de graduação de chama dotados de Manípulos em Baquelite',
			'Estrutura inferior sobre pés ou gabinete com portas',
			'Proteção traseira de Aço Inoxidável',
		],
	},
	{
		title: 'Coifa para Sistema de Exaustão',
		image: Coifa,
		features: [
			'Construção em Aço Inoxidável AISI 304, liga 18/8',
			'Provida na parte inferior em todo o seu perímetro de Calha Coletora de Gordura, tendo ainda abertura para ligação dos dutos de exaustão forçada',
			'Filtros modulares laváveis',
			'Suportes laterais para utensílios (opcional)',
		],
	},
	{
		title: 'Forno A Gás / Elétrico - Câmaras',
		image: NotFound,
		features: [
			'Construção em Aço Inoxidável AISI 304, liga 18/8',
			'Isolamento térmico em lã de vidro',
			'Dimensões da câmara: 725x835x480 mm',
		],
	},
	{
		title: 'Chapa Bifeteira de Sobrepor ao Fogão',
		image: NotFound,
		features: ['Construída em Ferro Fundido'],
	},
	{
		title: 'Estufa Vertical Aquecida Elétrica',
		image: NotFound,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8 interna e externa	(frente e laterais)',
			'Trilhos para receber GN’s com ajuste de alturas e (opção) prateleiras internas gradeadas, removíveis em aço inoxidável AISI 304, liga 18/8',
			'Gaxetas especial de vedação; Isolamento térmico em poliuretano injetado',
			'Aquecimento através de resistências elétricas aletadas para melhor distribuição de calor',
			'Termocontrolador digital para controle da temperatura e chave liga/desliga',
			'Pés em polietileno com sapatas niveladoras com rosqueamento embutido',
			'Portas em Aço Inoxidável AISI 304 com enchimento em poliuretano injetado',
			'Capacidade para 34 e 68 GN`s',
		],
	},
	{
		title: 'Banho-Maria a Gás e Elétrico',
		image: NotFound,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Cuba c/ sobre-fundo perfurado e dreno, para receber GN’S 1/1 (não incluso)',
			'Painel frontal em Aço Inoxidável',
			'Queimador tubular esmaltado (modelo à gás)',
			'Registro Industrial com graduação de chama e manípulos de baquelite (modelo a gás)',
			'Resistência Elétrica de Imersão controlada por termostato automático e lâmpada piloto (modelo elétrico)',
			'Estrutura inferior com prateleira gradeada ou gabinete com portas',
		],
	},
	{
		title: 'Sistema de Exaustão Completo',
		image: NotFound,
		features: [
			'Construção em Aço Inox AISI 304, liga 18/8, composto de: Duto Chapéu Chinês; Curvas 45 º e 90º',
			'Exaustor Axial e Centrífugo; Lavador de Gases; Filtro Eletrostático',
			'Podendo ser executado em Aço 430 e Aço Galvanizado',
			'Esse sistema será desenvolvido sob projeto e deverá ser dimensionado de acordo com a necessidade para o perfeito funcionamento',
		],
	},
	{
		title: 'Pass Through Aquecido',
		image: NotFound,
		features: [
			'Construção interna e externa em Aço Inoxidável AISI 304, liga 18/8',
			'Trilhos para receber GN’s 2/1 com ajuste de alturas e (opção) prateleiras internas gradeadas, removíveis em aço inoxidável AISI 304, liga 18/8',
			'Gaxetas especiais de vedação',
			'Portas em Aço Inoxidável AISI 304 com poliuretano injetado',
			'Isolamento térmico em poliuretano injetado',
			'Aquecimento através de resistências elétricas aletadas para melhor distribuição de calor',
			'Termocontrolador digital para controle da temperatura e chave liga/desliga',
			'Pés em polietileno com sapatas niveladoras com rosqueamento embutidas',
			'1 ou 2 portas de vidro',
			'Capacidade para 34 e 68 GN`s (não inclusas)',
		],
	},
];

export const cookerLinhaCompacta = [
	{
		title: 'Fogões a Gás',
		image: FogaoCompacta,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Grelhas especiais em perfís de Aço 1020',
			'Queimadores em ferro Fundido Especial, desmontáveis para facilitar a limpeza',
			'Conjunto de Bandejas Aparadoras em Chapa de Aço inoxidável AISI 304, liga 18/8',
			'Tubo de Distribuição em Aço Qualificado tratado',
			'Painel frontal em Aço Inoxidável',
			'Registro de Graduação de chama com Manípulos em Baquelite',
			'De encosto',
			'Grelhas de 350 x 300 mm',
		],
	},
	{
		title: 'Char Broiler a Gás',
		image: CharBroilerCompacta,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Grelhas de ferro fundido modulares',
			'Bandeja aparadora e suporte frontal móvel para resíduos',
			'Aquecimento a gás através de queimadores multitubulares com transmissão de calor por meio de pedras refratárias para alta produção de grelhados',
			'Painel Frontal em Aço Inoxidável',
			'Registro industrial de graduação de chama com Manípulos em Baquelite',
			'De sobrepor',
		],
	},
	{
		title: 'Chapa a Gás / Elétrica',
		image: ChapaCompacta,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Chapa Superior em Aço Qualificado Tratado Aço 1020 espessura de 12,25 mm',
			'Queimadores tubulares',
			'Resistências Elétricas (modelo elétrico)',
			'Controle de Temperatura para Termostato e Lâmpada Piloto (modelo elétrico)',
			'Comandos múltiplos por Zona de calor com controles independentes',
			'Registro industrial com graduações de chama',
			'Gaveta coletora de resíduos',
			'Painel Frontal em Aço Inoxidável e Manípulos em Baquelite',
		],
	},
	{
		title: 'Módulos de Base',
		image: GabineteCompacta,
		features: ['Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8'],
	},
	{
		title: 'Fritadeira Elétrica de Sobrepor - 9 L',
		image: FritadeiraCompacta,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Resistências Especiais Blindadas em aço Inoxidável',
			'Termostato automático para controle da temperatura com chave de liga/desliga e lâmpada piloto',
			'Termostato de Segurança',
			'Painel frontal em aço inoxidável AISI 304, liga 18/8',
			'Capacidade para "9 litros de óleo, 1 cuba, 2 cestos, Potência: 3.000 kW 220V – monofásico", ou "9 + 9 litros de óleo, 2 cubas, 2 cestos, Potência 6.000 kW 220V - monofásico"',
		],
	},
	{
		title: 'Módulo Neutro 350 e 700 mm',
		image: NotFound,
		features: [
			'Módulo Neutro de apoio',
			'Construção em Aço Inox AISI 304, liga 18/8',
			'Gavetas com trilhos telescópicos',
			'Cuba em aço inoxidável medindo 305 x 510 x 200 mm com válvula americana e torneira com bica móvel para encaixe de GN`s 1/1 x 150 mm',
		],
	},
	{
		title: 'Cozedor de Massas a Gás - 9 / 18 L',
		image: NotFound,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Aquecimento através de queimadores a gás de alto rendimento',
			'Registro industrial de graduação de chama dotados de Manípulos em Baquelite',
			'Conexão com registro para entrada de água e dreno',
			'Ladrão para Controle de Nível de água e saída do amido',
			'Painel frontal em Aço Inoxidável',
			'Gabinete inferior em aço escovado com portas, cuba em aço inoxidável com capacidade de 9 / 18 litros',
		],
	},
	{
		title: 'Banho-Maria a Gás e Elétrico',
		image: NotFound,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Cuba c/ sobre-fundo perfurado e dreno, para receber GN’S 1/1 (não incluso)',
			'Painel frontal em Aço Inoxidável',
			'Queimador tubular esmaltado (modelo à gás)',
			'Registro Industrial c/ graduação de chama e manípulos de baquelite (modelo à gás)',
			'Resistência Elétrica de Imersão controlada por termostato automático e lâmpada piloto(modelo Elétrico)',
		],
	},
	{
		title: 'Conservador de Frituras - Elétrico',
		image: NotFound,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Painel frontal em Aço Inoxidável',
			'Botão liga desliga com sinalizador luminoso de operação',
			'Calha de escoamento perfurada com inclinação',
			'Aquecimento através de lâmpada halógena',
		],
	},
	{
		title: 'Cooker French Chef Line',
		image: NotFound,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Placa superior e anel interno em aço tratado com espessura ideal',
			'Queimador à gás de alto rendimento (6500 kcal), em ferro fundido com 2 anéis de chama',
			'Registro Industrial com graduação de chama; Bandeja Coletora de resíduos em aço inoxidável',
			'Chaminé para saída dos gases da combustão em aço inoxidável',
			'Painel frontal em aço inoxidável e manípulos em baquelite com ajuste de chama alto e baixo',
			'Placa superior com gradiente de temperatura de 450˚ a 200˚c',
			'Queimador à gás de alto rendimento : 6500 kcal/h',
			'Gabinete em aço inoxidável',
		],
	},
	{
		title: 'Estufa de Base para Pratos',
		image: NotFound,
		features: [
			'Construção extra reforçada em Aço Inoxidável AISI 304, liga 18/8',
			'Aquecimento através de resistência elétrica 110 V, 220 V',
			'Termostato automático para controle de temperatura até 70oC',
			'Lâmpada piloto',
			'Prateleira intermediária em Aço Inoxidável',
		],
	},
];

export const refriMasterLine = [
	{
		title: 'Refrigeradores Master Line Horizontais',
		image: RefrigeradorHorizontal,
		features: [
			'Construção interno e externo em Aço Inoxidável AISI 304, liga 18/8',
			'Prateleiras internas gradeadas, removíveis em aço inoxidável AISI 304, com opção de trilhos reguláveis para GN”s 1/1',
			'Gaxetas especiais de vedação',
			'Isolamento térmico em Poliuretano Injetado',
			'Composta de unidade condensadora com compressor do tipo hermético',
			'Evaporador do sistema tipo “COLD WALL”, constituído de serpentina embutida no corpo do equipamento, permitindo uniformização do frio, evitando a presença de forçador, aumentando assim o volume interno útil de armazenamento, dotado ainda de todos os demais componentes necessários ao seu perfeito funcionamento',
			'Tampo de Aço Inoxidável reforçado',
			'Acessórios: Puxadores especiais metálicos, Termocontrololador de temperatura digital com degelo automático, Interruptor com sinalizador luminoso',
		],
	},
	{
		title: 'Freezeres Master Line Horizontais',
		image: FreezerHorizontal,
		features: [
			'Construção interno e externo em Aço Inoxidável AISI 304, liga 18/8',
			'Prateleiras internas gradeadas, removíveis em aço inoxidável AISI 304, com opção de trilhos reguláveis para GN”s 1/1',
			'Gaxetas especiais de vedação',
			'Isolamento térmico em Poliuretano Injetado',
			'Composta de unidade condensadora com compressor do tipo hermético',
			'Evaporador do sistema tipo “COLD WALL”, constituído de serpentina embutida no corpo do equipamento, permitindo uniformização do frio, evitando a presença de forçador, aumentando assim o volume interno útil de armazenamento, dotado ainda de todos os demais componentes necessários ao seu perfeito funcionamento',
			'Batentes com aquecimento através de Resistências Elétricas',
			'Tampo de Aço Inoxidável reforçado',
			'Acessórios: Puxadores especiais metálicos, Termocontrololador de temperatura digital com degelo automático, Interruptor com sinalizador luminoso',
		],
	},
	{
		title: 'Refrigeradores / Freezeres Verticais',
		image: FreezerVertical,
		features: [
			'Construção interno e externo em Aço Inoxidável AISI 304, liga 18/8',
			'Prateleiras internas gradeadas, removíveis, em Aço Inoxidável AISI 304, liga 18/8',
			'Portas com revestimento interno e externo em Aço Inoxidável AISI 304, liga 18/8, dotada de Gaxetas Especial de vedação',
			'Isolamento térmico em Poliuretano Injetado',
			'Composta de unidade condensadora com compressor do tipo hermético',
			'Evaporador de Ar Forçado',
			'Acessórios: Puxadores especiais metálicos, Termocontrololador de temperatura digital com degelo automático, Interruptor com sinalizador luminoso',
		],
	},
	{
		title: 'Pass Through',
		image: NotFound,
		features: [
			'Gabinete com revestimento interno, externo, frente e laterais em aço inoxidável AISI 304, liga 18/8; Dotado de Gaxetas especiais de vedação',
			'Trilhos para receber GN’s com ajuste de alturas e (opção) prateleiras internas gradeadas, removíveis em Aço Inoxidável AISI 304, liga 18/8',
			'Isolamento térmico em Poliuretano Injetado',
			'Composto de unidade condensadora com compressor do tipo hermético e Evaporador de Ar Forçado',
			'Capacidade para 34 e 68 GN`s',
			'Acessórios: Puxadores especiais metálicos, Termocontrololador de temperatura digital com degelo automático, Interruptor com sinalizador luminoso',
		],
	},
	{
		title: 'Módulo Refrigerado / Neutro',
		image: NotFound,
		features: [
			'Construção em Aço Inoxidável',
			'Cuba refrigerada com serpentina isolada térmicamente em poliuretano',
			'Capacidade para 18 GN’s 1/9x65 (opcional)',
			'Equipamento próprio para montagem sobre base',
			'Unidade refrigerada instalada na parte inferior',
		],
	},
];

export const distribuicao = [
	{
		title: 'Neutro / Aquecido / Refrigerado',
		image: Distribuicao,
		features: [
			'Módulos Desenvolvidos em Aço Inox 304, liga 18/8',
			'Gabinetes em Aço Inoxidável ou outros materiais tais como Granito, Marcenaria, especiais, oferecendo requinte e funcionalidade para servir alimentos, sejam eles quentes ou refrigerados',
		],
	},
	{
		title: 'Módulo Distribuição Aquecido Meia Saia',
		image: NotFound,
		features: [
			'Módulos construídos inteiramente em Aço Inoxidável',
			'Banho-Maria com capacidade para receber Gn’s',
			'Aquecimento por meio de resistências elétricas de imersão ou seca',
			'Comando tesmostático 20˚ a 120˚C e lâmpada piloto',
			'Pés tubulares Ø 38mm com sapatas niveladoras em polietileno',
			'Contraventamento Ø 25mm, confeccionado em Aço Inoxidável AISI-304 liga 18/8',
			'Conexões rosqueáveis para entrada e saída de água',
		],
		accessories: [
			'Correbandejas vincado ou tubular com mão francesa confeccionado em Aço Inox ou Granito',
			'Suporte Tubular 38 mm',
			'Protetor Superior em vidro temperado curvo ou plano',
			'Tampo em aço inoxidável ou granito especial',
			'Luminária (opcional)',
			'Rodízios (opcional)',
		],
	},
	{
		title: 'Módulo Distribuição Aquecido Gabinetado',
		image: NotFound,
		features: [
			'Módulo Construído Inteiramente em Aço inoxidável',
			'Banho-Maria com capacidade para receber Gn’s',
			'Aquecimento por meio de resistências elétricas de imersão ou seca',
			'Comando tesmostático 20˚ a 120˚C e lâmpada piloto',
			'Conexões rosqueáveis para entrada e saída de água',
		],
		accessories: [
			'Correbandejas vincado ou tubular com mão francesa confeccionado em Aço Inox ou Granito',
			'Suporte Tubular 38 mm',
			'Protetor Superior em vidro temperado curvo ou plano',
			'Tampo em aço inoxidável ou granito especial',
			'Luminária (opcional)',
			'Rodízios (opcional)',
			'Acabamento Externo opcional em revestimentos melamínico cores a escolher',
			'Padrão fórmica',
		],
	},
	{
		title: 'Módulo Distribuição Aquecido para Embutir',
		image: NotFound,
		features: [
			'Módulo Construído Inteiramente em Aço inoxidável',
			'Banho-Maria com capacidade para receber Gn’s',
			'Aquecimento por meio de resistências elétricas de imersão ou seca',
			'Comando tesmostático 20˚ a 120˚C e lâmpada piloto',
			'Conexões rosqueáveis para entrada e saída de água',
		],
		accessories: [
			'Correbandejas vincado ou tubular com mão francesa confeccionado em Aço Inox ou Granito',
			'Suporte Tubular 38 mm',
			'Protetor Superior em vidro temperado curvo ou plano',
			'Luminária (opcional)',
		],
	},
	{
		title: 'Módulo Distribuição Refrigerado Meia Saia',
		image: NotFound,
		features: [
			'Módulo Meia Saia Construído inteiramente em Aço Inoxidável',
			'Pista fria com capacidade para receber GN’s',
			'Cabine compressora com porta veneziana ou comando remoto à distância',
			'Refrigeração por meio de Serpentina',
			'Unidade condensadora do tipo hermético',
			'Isolamento térmico em poliuretano injetado expandido com 40mm de espessura',
			'Pés tubulares Ø 38 mm',
			'Sapatas niveladoras em polietileno',
			'Contraventamento Ø 25mm confeccionado em Aço Inox AISI 304 Liga 18/8',
		],
		accessories: [
			'Correbandejas vincado ou tubular com mão francesa confeccionado em Aço Inox ou Granito',
			'Suporte Tubular 38 mm',
			'Protetor Superior em vidro temperado curvo ou plano',
			'Tampo em aço inoxidável ou granito especial',
			'Luminária (opcional)',
			'Rodízios (opcional)',
		],
	},
	{
		title: 'Módulo Distribuição Refrigerado Gabinetado',
		image: NotFound,
		features: [
			'Módulo gabinete construído inteiramente em Aço Inoxidável',
			'Pista fria com capacidade para receber GN’s',
			'Cabine compressora com porta veneziana ou comando remoto à distância',
			'Refrigeração por meio de Serpentina',
			'Unidade condensadora do tipo hermético',
			'Isolamento térmico em poliuretano injetado expandido com 40mm de espessura',
		],
		accessories: [
			'Correbandejas vincado ou tubular com mão francesa confeccionado em Aço Inox ou Granito',
			'Gabinete refrigerado com portas para receber gn’s ou portas padrão para bebidas',
			'Suporte Tubular 38 mm',
			'Protetor Superior em vidro temperado curvo ou plano',
			'Tampo em aço inoxidável ou granito especial',
			'Luminária (opcional)',
			'Rodízios (opcional)',
			'Acabamento Externo opcional em revestimentos melamínico cores a escolher padrão fórmica',
		],
	},
	{
		title: 'Módulo Distribuição Refrigerado - Embutir',
		image: NotFound,
		features: [
			'Módulo Construído Inteiramente em Aço inoxidável',
			'Pista fria com capacidade para receber GN’s',
			'Refrigeração por meio de Serpentina',
			'Compressor hermético instalado na parte inferior',
			'Isolamento térmico em poliuretano injetado',
		],
		accessories: [
			'Correbandejas vincado ou tubular com mão francesa confeccionado em Aço Inox ou Granito',
			'Suporte Tubular 38 mm',
			'Protetor Superior em vidro temperado curvo ou plano',
			'Luminária (opcional)',
		],
	},
	{
		title: 'Módulo Distribuição Neutro Meia Saia',
		image: NotFound,
		features: [
			'Módulos meia-saia construídos inteiramente em aço inoxidável',
			'Tampo com vão para receber GN’s',
			'Pés Ø 38mm com sapatas niveladoras em polietileno e contraventamento Ø 25mm',
			'Confeccionado em Aço Inoxidável AISI-304, Liga 18/8',
		],
		accessories: [
			'Correbandejas vincado ou tubular com mão francesa confeccionado em Aço Inox ou Granito',
			'Suporte Tubular 38 mm',
			'Protetor Superior em vidro temperado curvo ou plano',
			'Luminária (opcional)',
		],
	},
	{
		title: 'Módulo Distribuição Porta Pratos',
		image: NotFound,
		features: [
			'Módulo Distribuição Porta Pratos, Talheres e Bandejas',
			'Módulo construído inteiramente em Aço Inoxidável',
			'Base tipo gabinete com portas ou meia saia',
			'Estrutura superior tubular inoxidável com compartimentos para pães e talheres',
		],
		accessories: [
			'Correbandejas vincado ou tubular com mão francesa confeccionado em Aço Inox ou Granito',
			'Tampo em aço inoxidável ou granito;',
			'Rodízios (opcional)',
		],
	},
	{
		title: 'Expositores Superiores',
		image: NotFound,
		features: [
			'Suporte tubular em aço inoxidável AISI-304',
			'Acabamento escovado',
			'Calhas de sustentação em Aço Inoxidável acabamento escovado',
			'Vidro Plano temperado e removível para controle de higiene',
			'Prateleira intermediária em vidro temperado plano (opcional)',
		],
	},
];

export const barMasterLine = [
	{
		title: 'Refrigeradores Horizontais para Bebidas',
		image: NotFound,
		features: [
			'Construção em Aço Inoxidável AISI 304, liga 18/8',
			'2 ou 3 portas em Aço Inoxidável ou vidro',
			'Duas alturas com diferentes capacidade de volume interno',
		],
	},
	{
		title: 'Acessórios para Bar',
		image: NotFound,
		features: [
			'Suporte para garrafas',
			'Extensor do suporte para garrafas',
			'Suporte para toalhas',
			'Porta condimentos',
			'Suporte para liquidifícador',
			'Abridor de garrafas',
			'Torneiras',
		],
	},
	{
		title: 'Diversos para Bar',
		image: NotFound,
		features: [
			'Estação para coquetéis',
			'Módulo prateleira',
			'Módulo prateleira com dreno',
			'Módulo para gavetas de lavadora de copos',
			'Módulo com 1,2, ou 3 cubas',
			'Módulo neutro',
			'Módulo p/ liquidifícador',
			'Módulo lixeiro',
			'Módulo para guarda de copos',
			'Plano ponte',
			'Plano ponte escorredor',
			'Lavatório para mão',
			'Módulo de canto',
		],
	},
	{
		title: 'Refrigeradores para Garrafas e Freezeres para Copos',
		image: NotFound,
		features: [
			'Construção em Aço Inoxidável AISI 304, liga 18/8',
			'Capacidade para 200 litros',
			'Tampa superior deslizante',
		],
	},
];

export const mobiliarioMasterLine = [
	{
		title: 'Buffets',
		image: Buffets,
		features: [
			'Construção em Aço Inoxidável AISI-304, liga 18/8',
			'Prateleira inferior junto a base',
			'Corpo em Aço inoxidável',
			'Tampo em aço inoxidável',
			'Pés em polietileno com sapatas niveladoras',
			'Prateleira intermediária em aço inoxidável (opcional)',
			'Gavetas (opcional)',
		],
	},
	{
		title: 'Estante Lisa ou Gradeada',
		image: Estante,
		features: [
			'Construção em Aço Inoxidável AISI-304, liga 18/8',
			'150 / 300 kg por plano',
			'Totalmente desmontável',
			'Bitola 18',
			'Planos lisos com reforço ou gradeados em perfis “U”',
			'Parafusos, porcas e arruelas em aço inoxidável',
		],
	},
	{
		title: 'Mesa Lisa',
		image: MesaLisa,
		features: [
			'Tampo superior em Aço Inoxidável AISI 304, liga 18/8, com Espelho de 100 mm onde tangenciam as paredes',
			'Bordas de 40 mm de altura nas partes livres',
			'Estrutura de apoio com quadro em perfis ”U” e pés tubulares em Aço Inox 304, liga 18/8 com diâmetro de 1 ½”, dotados de sapatas niveladoras de polietileno',
			'Contraventamento em tubo inox 304, liga 18/8, de 1”',
		],
	},
	{
		title: 'Mesa para Corte de Alimentos',
		image: MesaCorte,
		features: [
			'Tampo superior em Aço Inoxidável AISI 304, liga 18/8 com vão para encaixar a placa de polipropileno, com espelho de 100 mm onde tangenciam as paredes',
			'Bordas de 40 mm de altura nas partes livres',
			'Estrutura de apoio com quadro em perfis ”U” e pés tubulares em Aço Inox 304, liga 18/8 com diâmetro de 1 ½”, dotados de sapatas niveladoras de polietileno',
			'Contraventamento em tubo inox 304, liga 18/8, de 1”',
			'Placa de polipropileno para corte de alimentos em espessura de 20mm',
			'Medidas sob consulta',
		],
	},
	{
		title: 'Mesa do Chef',
		image: MesaChef,
		features: [
			'Tampo superior em Aço Inoxidável AISI 304, liga 18/8 com vão para encaixar a placa de polipropileno, com espelho de 100 mm onde tangenciam as paredes',
			'Bordas de 40 mm de altura nas partes livres e rebaixo de 10 mm para evitar que escorra água no piso, com CUBA',
			'Estrutura de apoio com quadro em perfis ”U” e pés tubulares em Aço Inox 304, liga 18/8 com diâmetro de 1 ½”, dotados de sapatas niveladoras de polietileno',
			'Contraventamento em tubo inox 304, liga 18/8, de 1”',
			'Gavetas',
			'Prateleira inferior lisa',
			'Prateleira superior lisa sobre montantes tubulares inoxidável',
		],
		accessories: ['Rack para GN`s 1/1', 'Gaveta em aço inoxidável para utensílios'],
	},
	{
		title: 'Mesa de Encosto com Cuba',
		image: MesaEncostoCuba,
		features: [
			'Tampo superior em Aço Inoxidável AISI 304, liga 18/8 com vão para encaixar a placa de polipropileno, com espelho de 100 mm onde tangenciam as paredes',
			'Bordas de 40 mm de altura nas partes livres, com CUBA',
			'Estrutura de apoio com quadro em perfis ”U” e pés tubulares em Aço Inox 304, liga 18/8 com diâmetro de 1 ½”, dotados de sapatas niveladoras de polietileno',
			'Contraventamento em tubo inox 304, liga 18/8, de 1”',
		],
	},
	{
		title: 'Prateleira Superior Lisa',
		image: PrateleiraLisa,
		features: [
			'Construção totalmente em Aço Inoxidável AISI 304, liga 18/8',
			'Bitola 18, espelho de 70 mm de altura onde tangenciam as paredes e bordas de 30mm nas partes livres',
			'Suporte tipo mãos francesas',
		],
	},
	{
		title: 'Prateleira Superior Gradeada',
		image: PrateleiraGradeada,
		features: [
			'Construção em perfis de Aço Inoxidável AISI 304, liga 18/8',
			'Bitola 18',
			'Suporte tipo mãos francesas',
		],
	},
	{
		title: 'Prateleira Superior Lisa Sobre Montantes',
		image: PrateleiraMontante,
		features: [
			'Construção em Aço Inoxidável AISI 304, liga 18/8',
			'Bitola 18',
			'Suporte em tubos inox escovado',
		],
	},
	{
		title: 'Tray Rest para Gaveta da Lavadora',
		image: TrayRest,
		features: [
			'Construção em chapa de aço inoxidável AISI 304, liga 18/8',
			'Suporte tipo mãos francesas ou tubulares',
			'Para gavetas 500 x 500 mm',
		],
	},
	{
		title: 'Tampo Liso de Encosto / Centro',
		image: TampoLiso,
		features: [
			'Construção em chapa de aço inoxidável AISI 304, liga 18/8, espelho de 100mm onde tangenciam as paredes e bordas de 40mm de altura nas partes livres',
		],
	},
	{
		title: 'Carro para Apoio de Forno Combinado',
		image: CarroApoioForno,
		features: ['Construção em Aço Inoxidável AISI-304 18/8', 'Medidas sob projeto'],
	},
	{
		title: 'Carro Auxiliar - Dois / Três Planos',
		image: CarroAuxiliar,
		features: [
			'Construído inteiramente em aço inoxidável AISI-304 18/8',
			'Tampo e prateleiras confeccionados em chapa de aço inoxidável AISI 304, liga 18/8',
			'Estrutura em perfis “U” com montantes tubulares em aço inoxidável',
			'Dotado de 04 rodízios de Ø4˝, sendo 02 fixos e 02 giratórios com freio',
			'Medidas especiais sob consulta',
		],
	},
	{
		title: 'Carro de Apoio para a Cocção',
		image: CarroApoioCoccao,
		features: [
			'Construção em Aço Inoxidável AISI-304 18/8',
			'Rodízios de Ø4 polegadas',
			'Medidas especiais sob consulta',
		],
	},
	{
		title: 'Carro Esqueleto para Transporte de GN’s',
		image: CarroEsqueleto,
		features: [
			'Construído inteiramente em Aço Inoxidável AISI-304 18/8',
			'Montantes em tubos de aço inoxidável',
			'Pares de cantoneiras em per l “L” para receber GN’S 2/1 ou assadeiras, confeccionado 	em Aço Inoxidável AISI -304 18',
			'Dotado de 4 rodízios de Ø4 polegadas, sendo 02  xos e 02 giratórios com freio',
			'Medidas especiais para qualquer tipo de bandejas sob consulta',
		],
	},
	{
		title: 'Guiche Metralhadora Multibox',
		image: Guiche,
		features: [
			'Construção em aço inoxidável AISI 304, liga 18/8',
			'Planos em chapa de aço inoxidável com bordas de 30mm em todo o seu perímetro',
			'Moldura em perfis para acabamento na parede',
			'Equipamento a ser fixado em Alvenaria',
			'Divisores em tubos de aço inoxidável',
			'Medidas Especiais sob consulta',
		],
	},
	{
		title: 'Lavatório',
		image: Lavatorio,
		features: [
			'Construção em aço inoxidável AISI 304, liga 18/8',
			'Bojo com espelho traseiro de 150mm, e bordas de 40mm na frente e laterais',
			'Bica tubular em aço inoxidável (opcional)',
			'Acionamento automático pelo joelho (opcional)',
			'Furo de 3 ½” para válvula americana',
			'Fixação através de mãos francesas',
		],
	},
	{
		title: 'Armário para Limpeza Plano',
		image: NotFound,
		features: [
			'Construção Aço Inoxidável AISI 304, liga 18/8',
			'Bitola 18”',
			'Equipado com 4 divisões internas e ganchos para vassouras',
			'Pés tubulares com sapatas para nivelamento ou rodízios de 4” (opcional)',
		],
	},
	{
		title: 'Armários Multi-box',
		image: NotFound,
		features: [
			'Construção Aço Inoxidável AISI 304, liga 18/8',
			'Bitola 18”',
			'Pés tubulares Ø 1 1/2˝ com sapata para nivelamento',
			'Box com porta preparado para receber cadeado',
		],
	},
	{
		title: 'Cubas Inox',
		image: NotFound,
		features: [
			'Construção em aço inoxidável AISI 304, liga 18/8 com todos os cantos arredondados formando amplo raio de curvatura no encontro de suas paredes',
			'Furo para válvula americana de 3 1/2˝',
		],
	},
	{
		title: 'Mesa para Preparo de Massas',
		image: NotFound,
		features: [
			'Tampo de granito na espessura de 20mm',
			'Estrutura de apoio com quadro em perfis ”U” e pés tubulares em Aço Inox 304, liga 18/8 com diâmetro de 1 ½”, dotados de sapatas 	niveladoras de Polietileno',
			'Contraventamento em tubo inox 304, liga 18/8, de 1”',
			'Medidas sob consulta',
		],
	},
	{
		title: 'Tray Rest Tubular para Gaveta da Lavadora',
		image: NotFound,
		features: [
			'Construção em tubos inoxidáveis de 1”, AISI 304, liga 18/8',
			'Suporte tipo Mãos Francesas',
			'Para Gavetas 500 x 500 mm',
		],
	},
	{
		title: 'Mesa para Apoio do Descascador',
		image: NotFound,
		features: [
			'Construído inteiramente em Aço Inoxidável AISI 304 18/8',
			'Tampo com orifício de Ø 100 mm para passagem da tubulação de esgoto de descascador com colarinho de 20mm, estrutura em quadro de cantoneiras em perfil “U”',
			'Pés Ø38 mm tubulares com sapatas niveladoras em polietileno',
			'Contraventamento Ø25 mm',
			'Caixa de coleta de líquidos com dreno e cesto perfurado removível, confeccionado em Aço Inoxidável AISI-304, liga 18/8',
		],
	},
	{
		title: 'Carro para Transporte de Detritos',
		image: NotFound,
		features: [
			'Construída inteiramente em Aço Inoxidável AISI 304 18/8',
			'Corpo cilíndrico',
			'Alças e tampa com pegador',
			'Dotado de rodízios de Ø3 polegadas giratórios',
			'Opções: Tampa Solta ou Com Pedal',
		],
	},
	{
		title: 'Carro para Remolho de Talheres',
		image: NotFound,
		features: [
			'Construída inteiramente em Aço Inoxidável AISI 304 18/8',
			'Cuba fixa provida de válvula americana e registro de fecho rápido',
			'Estrutura tubular em Aço Inoxidável AISI 304, liga 18/8',
			'Assentado sobre 4 rodízios de Ø3 polegadas, sendo 02 fixos e 02 giratórios',
		],
	},
	{
		title: 'Carro para Transporte de Pratos - 4 colunas',
		image: NotFound,
		features: [
			'Construída inteiramente em Aço Inoxidável AISI 304 18/8',
			'Base e colunas confeccionada em Aço Inoxidável AISI-304, liga 18/8',
			'Dotado de Rodízios de Ø4 polegadas, sendo 2 fixos e 2 giratórios com trava',
		],
	},
	{
		title: 'Carro Basculante para Lavagem de Cereais',
		image: NotFound,
		features: [
			'Construída inteiramente em Aço Inoxidável AISI 304 18/8',
			'Cuba',
			'Tela de contenção de Grãos',
			'Alças',
			'Sistema basculante da cuba e estrutura em tubo',
			'Dotado de rodízios de Ø4 polegadas, sendo 02 fixos e 02 giratórios com trava',
		],
	},
	{
		title: 'Carro Chassi',
		image: NotFound,
		features: [
			'Construção em Aço Inoxidável AISI-304 18.8',
			'Base em perfis e puxador em vergalhão Ø5/16˝',
			'Confeccionado em Aço inoxidável AISI-304 18.8',
			'Dotado de 04 rodízio Ø4˝, sendo 02 fixos e 02 giratórios com freio',
			'Para transporte de caixa e monoblocos plásticos',
		],
	},
	{
		title: 'Carro Isotérmico Basculante',
		image: NotFound,
		features: [
			'Construído inteiramente em Aço Inoxidável AISI-304 18/8',
			'Cuba com bica,tampa isotérmica,guidão em tubo Ø25mm e estrutura tubular confeccionada em Aço Inoxidável AISI-304,liga 18/8',
			'Isolamento em poliuretano injetado',
			'Dotado de 04 rodízio Ø4˝, sendo 02 fixos e 02 giratórios com freio',
			'Opções com Tampa ou sem Tampa',
			'Medidas especiais sob consulta',
		],
	},
	{
		title: 'Carro Plataforma',
		image: NotFound,
		features: [
			'Construção inteiramente em aço inoxidável AISI-304, 18/8',
			'Plataforma reforçada em chapa de aço inoxidável com bordas de 40mm em todo seu perímetro',
			'Guidão em tubo de aço inoxidável',
			'Dotado de 04 rodízio Ø4˝, sendo 02 fixos e 02 giratórios com freio',
			'Bitola 16 Ø1,1/4',
			'Medidas especiais sob consulta',
		],
	},
	{
		title: 'Lava Botas - Simples / Duplo',
		image: NotFound,
		features: [
			'Construção em aço inoxidável AISI 304, liga 18/8',
			'Bojo inclinado com bordas quadradas de 40mm em todo seu perímetro',
			'Chuveiro tubular inoxidável',
			'Suporte para apoio da bota em perfis inoxidável',
			'Furo de 3 ½” para válvula americana',
			'Pés tubular com sapatas niveladoras em polietileno',
		],
	},
	{
		title: 'Grelha de Piso',
		image: NotFound,
		features: [
			'Construção em aço inoxidável AISI 304, liga 18/8',
			'Caixilho em perfis “L” com grapa para ser chumbado na alvenaria',
			'Tampa em chapa de aço inoxidável dobrada com furos oblongos',
			'Calha para retenção de detritos em chapa inoxidável perfurada, removível',
			'Medidas sob Consulta',
		],
	},
];
