import React from 'react';

import Routes from './routes';

import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/global.scss';

function App() {
	return (
		<>
			<Routes />
		</>
	);
}

export default App;
